import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  ButtonStyles,
  ButtonComponentStyles,
  PageWrapper,
} from '../styles/GenericStyles'

const Wrapper404 = styled(PageWrapper)`
  .container {
    margin: 0 2em 2em 2em;

    li,
    h2,
    p {
      margin: 1em;
    }

    ul {
      text-align: center;
    }

    .gatsby-image-wrapper {
      max-height: 35vh;
      width: 100%;
      object-position: center bottom;
      object-fit: cover;
    }
  }
`

const StyledLink = styled(Link)`
  ${ButtonStyles}
  display: inline-block;
`

function Ups() {
  const data = useStaticQuery(graphql`
    query {
      cat: sanityPicture(id: { eq: "-b7d88f1f-f56d-5caf-b652-965c5059dffe" }) {
        altText
        picture {
          asset {
            fluid(maxWidth: 1000, maxHeight: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper404>
      <div className="container">
        <h2>Ups... there was a problem!</h2>
        <Img fluid={data.cat.picture.asset.fluid} alt={data.cat.altText} />
        <h2>
          It looks like this page does not exist. Let me get you out of this...
        </h2>
        <ul>
          <li>
            <p>
              If you think my page is broken, write me a message and tell me
              what happened:
            </p>
            <div>
              <StyledLink to="/#contact" deg="rotate(-7deg)">
                Contact
              </StyledLink>
            </div>
          </li>
          <li>
            <p>
              If you want to continue reading about my projects, click here:
            </p>
            <div>
              <StyledLink to="/#projects">Projects</StyledLink>
            </div>
          </li>
          <li>
            <p>
              If you are tired and need some funny cat videos, this is the
              place:
            </p>
            <div>
              <ButtonComponentStyles
                as="a"
                href="https://www.youtube.com/watch?v=3JJwmF09AlE"
                target="_blank"
                rel="noreferrer"
                deg="rotate(1080deg)"
              >
                yeaaahhh! Cats! 🐈
              </ButtonComponentStyles>
            </div>
          </li>
        </ul>
      </div>
    </Wrapper404>
  )
}

export default Ups
