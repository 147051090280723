import React from 'react'
import SEO from '../components/SEO'
import Ups from '../components/Ups'

function errorPage() {
  return (
    <>
      <SEO title="Ups! Something went wrong!" />
      <Ups />
    </>
  )
}

export default errorPage
